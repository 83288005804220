import React, { useEffect, useState } from "react";
import * as styles from "../../styles/company/company.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { TitleCover } from "../../atoms/title-cover/TitleCover";
import { SEO } from "../../atoms/seo/SEO";
import { DefaultLayout } from "../../organisms/DefaultLayout";
import visionImg from "../../images/vision/_kpo0870.jpg";
import seoImage from "../../images/vision/vision-osaka.jpg";
import circleImg from "../../images/vision/vision_circle.png";

/**
 * StaticImageに渡すパス。
 * import ** ではエラーになるのでstringを渡す。
 */
const IMAGE_PATH = {
  TITLE_COVER: "../../images/vision/vision-osaka.jpg",
  ITEM_SOCIETY: "../../images/vision/vision-society.png",
  ITEM_CUSTOMER: "../../images/vision/vision-customer.png",
  ITEM_EMPLOYEE: "../../images/vision/vision-employee.png",
};

export default ({ location: { pathname } }) => {
  const [isWhite, setIsWhite] = useState(true);
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsWhite(true);
      } else {
        setIsWhite(false);
      }
    });

    const targets = document.querySelectorAll(
      "[data-change-header-color-point]"
    );
    targets.forEach(target => {
      observer.observe(target);
    });
  }, []);

  return (
    <DefaultLayout isWhite={isWhite}>
      <SEO
        pathname={pathname}
        title="ビジョン | Vision"
        description="スプリーブホールディングスは、「この上ない最高の世界を創る」（We create the best world.）を掲げており、 社会、顧客、従業員に対して、「この上ない最高」を提供する会社を目指しています。"
        image={seoImage}
      />
      <div className={styles.Vision}>
        <div className={styles.Vision__title}>
          <TitleCover
            title="Vision"
            sub="ビジョン"
            background={
              <StaticImage
                src="../../images/vision/vision-osaka.jpg"
                alt="ビジョン"
                aspectRatio={16 / 9}
                style={{ width: "100%", height: "100%" }}
              />
            }
          />
        </div>

        {/* Intersection Observer 用にヘッダーのカラーを変えるポイントを仕込む */}
        <div data-change-header-color-point="data-change-header-color-point" />

        <div className={styles.Vision__contents}>
          <div className={styles.Vision__description}>
            <div>
              <h2>この上ない最高の世界を創る</h2>
              <h3>We create the best world</h3>
              <p>
                Suprieve
                Holdingsは、社会、顧客、従業員に対して、「この上ない最高」を提供する会社を目指しています。このビジョンの実現に向けて、超一流のパートナーと共に、最先端のテクノロジーと最も優れたビジネスモデルを用いて、あらゆる事業を永続性と拡大性を兼ね備えて推進していきます。
              </p>
            </div>
          </div>

          <img
            className={styles.Vision__visionImage}
            src={visionImg}
            alt="vision この上ない最高の世界を創る"
          />

          <div className={styles.Vision__mission}>
            <h2>Mission</h2>
            <span>ミッション</span>
          </div>

          <div className={styles.Vision__leadingCompany}>
            <h2>全ての事業をリーディングカンパニーへ</h2>
            <h3>Our businesses to the leading.</h3>
            <p>
              リーディングカンパニーとは、ある分野の「最高」を追求した結果です。
              <br />
              Suprieve
              Holdingsが行う全ての事業で、業界でトップを走るリーディングカンパニーを目指します。
            </p>
          </div>

          <div className={styles.Vision__definition}>
            <div className={styles.Vision__definitionText}>
              <h2>社会、顧客、従業員を幸せに</h2>
            </div>
            <img
              className={styles.Vision__definitionImage}
              src={circleImg}
              alt="社会、顧客、従業員を幸せに"
            />
          </div>

          <div
            className={`${styles.Vision__society} ${styles.Vision__missionItem}`}
          >
            <StaticImage
              className={styles.Vision__societyImage}
              src="../../images/vision/vision-society.png"
              alt="社会"
            />
            <div className="flex-1 flex d-align-items-center">
              <div className={styles.Vision__societyInformation}>
                <h3>社会</h3>
                <p>
                  Suprieveは、社会が抱えているあらゆる課題の解決に向けて貢献することを目指します。
                  <br />
                  そして、社会貢献活動のリーディングカンパニーへと成長し、
                  <br />
                  10年100年1000年と永続性と拡大性を保つことで、社会の全ての人に必要とされる会社になります。
                </p>
              </div>
            </div>
          </div>

          <div
            className={`${styles.Vision__customer} ${styles.Vision__missionItem}`}
          >
            <div className="flex flex-1 d-align-items-center">
              <div className={styles.Vision__customerInformation}>
                <h3>顧客</h3>
                <p>
                  Suprieveは、顧客に対してよりよいサービスの提供・商品の開発・提供方法の模索を半永久的に繰り返す会社にしたいです。バージョンアップを繰り返すことで、顧客の価値が上がり、果ては顧客と繋がるまで喜ばれると考えております。
                </p>
              </div>
            </div>
            <StaticImage
              className={styles.Vision__customerImage}
              src="../../images/vision/vision-customer.png"
              alt="顧客"
            />
          </div>

          <div
            className={`${styles.Vision__employee} ${styles.Vision__missionItem}`}
          >
            <StaticImage
              className={styles.Vision__employeeImage}
              src="../../images/vision/vision-employee.png"
              alt="従業員"
            />
            <div className="flex flex-1 d-align-items-center">
              <div className={styles.Vision__employeeInformation}>
                <h3>従業員</h3>
                <p>
                  Suprieveは、素晴らしい従業員とビジョンに向けて突き進みます。もちろん従業員に対してもこの上ない最高のキャリアや人生を送っていただくために、事業選択やあらゆる制度を整えます。何よりも「この会社に入って最高だった」と思っていただくためです。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
