// extracted by mini-css-extract-plugin
export var Vision = "f_K";
export var Vision__title = "f_L";
export var Vision__description = "f_M";
export var Vision__visionImage = "f_N";
export var Vision__mission = "f_P";
export var Vision__leadingCompany = "f_Q";
export var Vision__definition = "f_R";
export var Vision__definitionText = "f_S";
export var Vision__definitionImage = "f_T";
export var Vision__missionItem = "f_V";
export var Vision__society = "f_W";
export var Vision__societyImage = "f_X";
export var Vision__customerImage = "f_Y";
export var Vision__employeeImage = "f_Z";
export var Vision__societyInformation = "f_0";
export var Vision__customer = "f_1";
export var Vision__customerInformation = "f_2";
export var Vision__employee = "f_3";
export var Vision__employeeInformation = "f_4";